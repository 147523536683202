import React, { CSSProperties } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './BackToLink.module.scss';

type Props = {
    marginBottom?: string | number,
    marginLeft?: string | number,
    marginRight?: string | number,
    marginTop?: string | number,
    to?: string
}

const BackSvg = ({ style }: {style: CSSProperties}) => (
    <svg
        style={style}
        width="8" height="8" viewBox="0 0 8 8" fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M8 3.5H1.915L4.71 0.705L4 0L0 4L4 8L4.705 7.295L1.915 4.5H8V3.5Z" fill="#0B78A2" />
    </svg>
);

const BackToLink = ({
    marginBottom = 10,
    marginLeft = 0,
    marginRight = 0,
    marginTop = 10,
} : Props) => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <Link
                to="/"
                style={{
                    marginBottom,
                    marginLeft,
                    marginRight,
                    marginTop,
                    color: '#0B78A2',
                }}
                onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                }}
            >
                <BackSvg style={{
                    marginRight: 5 }}
                />
                <span style={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16px',
                    letterSpacing: '0.25px',
                    color: '#0B78A2',
                }}
                >
                    Back
                </span>
            </Link>
        </div>
    );
};

export default BackToLink;
